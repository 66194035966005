import Image from 'next/image'
import Link from 'next/link'
import { LanguageContext } from '../hook/context'
import { useContext } from 'react'

const Footer = () => {
  const { t } = useContext(LanguageContext)
  return (
    <footer className='flex h-52 flex-col justify-between border-t border-neutral-800 pt-5  pb-5 text-neutral-400  '>
      <div className='items-center flex flex-col text-center sm:items-start sm:text-left'>
        <Image
          src='/logos/dcd-w-logo.svg'
          width='113'
          height='30'
          alt='DoCode Logo'
        />
        {t.footer.description}
      </div>
      <div className='flex items-center justify-between'>
        {t.footer.copyRight}
        <div className='flex space-x-2 '>
          <Link href='https://www.instagram.com/colorwayxpress/'>
            <Image
              className='socialIcons'
              src='/assets/Brands/instagram.svg'
              width='34'
              height='34'
              alt='DoCode Logo'
            />
          </Link>
          <Link href='https://www.linkedin.com/company/docode-software/'>
            <Image
              className='socialIcons'
              src='/assets/Brands/linkedin.svg'
              width='34'
              height='34'
              alt='DoCode Logo'
            />
          </Link>
          <Link href='https://www.youtube.com/channel/UCIqvrVzg9oCufhKAmHFuT7w/videos'>
            <Image
              className='socialIcons'
              src='/assets/Brands/youtube.svg'
              width='34'
              height='34'
              alt='DoCode Logo'
            />
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
